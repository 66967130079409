import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import type { OperationStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { handleNgxsError } from '@cosmos/util-common';
import { AutocompleteService } from '@esp/autocomplete/data-access-api';
import type {
  AutocompleteParams,
  User,
} from '@esp/autocomplete/types-autocomplete';
import type { ProjectResolution } from '@esp/orders/types';
import type {
  AllowEditOrderNumberSetting,
  OrderStatusType,
  TenantOrderNumber,
} from '@esp/settings/types';

import { OrderSettingsActions } from '../actions';
import { ProjectResolutionsApiService, SettingsService } from '../services';

import { TOAST_MESSAGES } from './toast-messages';

export interface OrderSettingsStateModel {
  OrderNumber?: TenantOrderNumber;
  AllowEditOrderNumber?: AllowEditOrderNumberSetting;
  OrderStatusType?: OrderStatusType[];
  ProjectResolutions?: ProjectResolution[];
  users?: User[];
  loading?: OperationStatus;
}

type LocalStateContext = StateContext<OrderSettingsStateModel>;

@State<OrderSettingsStateModel>({
  name: 'orderSettings',
  defaults: {
    OrderNumber: undefined,
    AllowEditOrderNumber: undefined,
    OrderStatusType: undefined,
    ProjectResolutions: undefined,
  },
})
@Injectable()
export class OrderSettingsState {
  constructor(
    private readonly _service: SettingsService,
    private readonly _resolutionsService: ProjectResolutionsApiService,
    private readonly _autoCompleteService: AutocompleteService
  ) {}

  @Action(OrderSettingsActions.LoadOrderNumber)
  private _loadOrderNumber(ctx: LocalStateContext) {
    return this._service.orderNumber().pipe(
      tap((res) => {
        ctx.patchState({ OrderNumber: res });
      })
    );
  }

  @Action(OrderSettingsActions.UpdateOrderNumberSetting)
  private _updateOrderNumber(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.UpdateOrderNumberSetting
  ) {
    return this._service.updateOrderNumber(payload).pipe(
      tap((res) => {
        ctx.patchState({ OrderNumber: res });
      }),
      tap(() => {
        ctx.dispatch(new OrderSettingsActions.LoadOrderNumber());
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.EDIT_STARTING_NUMBER_FAIL())
        );
        return EMPTY;
      })
    );
  }

  @Action(OrderSettingsActions.LoadAllowEditOrderNumber)
  private _loadAllowEditOrderNumber(ctx: LocalStateContext) {
    return this._service.allowEditOrderNumber().pipe(
      tap((res) => {
        ctx.patchState({
          AllowEditOrderNumber: {
            ...res,
            Value: (!res.Value || res.Value) === 'false' ? false : true,
          },
        });
      })
    );
  }

  @Action(OrderSettingsActions.UpdateAllowEditOrderNumber)
  private _updateAllowEditOrderNumber(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.UpdateAllowEditOrderNumber
  ) {
    return this._service.updateAllowEditOrderNumber(payload).pipe(
      tap((res) => {
        ctx.patchState({ AllowEditOrderNumber: res });
        ctx.dispatch(new OrderSettingsActions.LoadAllowEditOrderNumber());
      }),
      handleNgxsError(() =>
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.ALLOW_USER_OVERRIDES_FAIL())
        )
      )
    );
  }

  @Action(OrderSettingsActions.LoadOrderStatus)
  private _loadOrderStatus(ctx: LocalStateContext) {
    return this._service.orderStatusType().pipe(
      tap((res) => {
        ctx.patchState({ OrderStatusType: res });
      })
    );
  }

  @Action(OrderSettingsActions.CreateOrderStatus)
  private _createOrderStatus(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.CreateOrderStatus
  ) {
    return this._service.createOrderStatusTypes(payload).pipe(
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.CREATE_STATUS_TYPE_FAIL())
        );
        return EMPTY;
      })
    );
  }

  @Action(OrderSettingsActions.UpdateOrderStatus)
  private _updateOrderStatus(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.UpdateOrderStatus
  ) {
    return this._service.updateOrderStatusType(payload.Id, payload).pipe(
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.UPDATE_STATUS_TYPE_FAIL(payload.Label)
          )
        );
        return EMPTY;
      })
    );
  }

  @Action(OrderSettingsActions.RemoveOrderStatus)
  private _removeOrderStatus(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.RemoveOrderStatus
  ) {
    return this._service.deleteOrderStatusType(payload.Id).pipe(
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DELETE_STATUS_TYPE_FAIL(payload.Label)
          )
        );
        return EMPTY;
      })
    );
  }

  @Action(OrderSettingsActions.LoadResolutions)
  private _loadResolutions(ctx: LocalStateContext) {
    return this._resolutionsService
      .getResolutions()
      .pipe(tap((res) => ctx.patchState({ ProjectResolutions: res })));
  }

  @Action(OrderSettingsActions.CreateResolution)
  private _createResolutions(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.CreateResolution
  ) {
    return this._resolutionsService.postResolution(payload);
  }

  @Action(OrderSettingsActions.UpdateResolutions)
  private _updateResolutions(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.UpdateResolutions
  ) {
    return this._resolutionsService.putResolution(payload);
  }

  @Action(OrderSettingsActions.RemoveResolutions)
  private _removeResolutions(
    ctx: LocalStateContext,
    { payload }: OrderSettingsActions.RemoveResolutions
  ) {
    return this._resolutionsService.deleteResolution(payload).pipe(
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DELETE_RESOLUTION_FAIL())
        );
        return EMPTY;
      })
    );
  }

  @Action(OrderSettingsActions.LoadUsers)
  private _loadUsers(ctx: LocalStateContext) {
    return this._autoCompleteService.users({} as AutocompleteParams).pipe(
      tap((users) => {
        ctx.patchState({ users });
      }),
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
