import { createSelector } from '@ngxs/store';

import { createLoadingSelectorsFor } from '@cosmos/state';
import { ThirdPartyRecordOption } from '@cosmos/types-common';
import { User, UserProfileQueries } from '@esp/auth/data-access-auth';
import type {
  CompanyProfileSetting,
  CompanyProfileSettingCode,
} from '@esp/settings/types';

import { CompanyProfileState, type CompanyProfileStateModel } from '../states';

export namespace CompanyProfileQueries {
  export const { isLoading, hasLoaded, getLoadError } =
    createLoadingSelectorsFor<CompanyProfileStateModel>(CompanyProfileState);

  export const getCompanyProfile = createSelector(
    [CompanyProfileState],
    (state: CompanyProfileStateModel) => {
      return state.settings;
    }
  );

  export const updateStatus = createSelector(
    [CompanyProfileState],
    (state: CompanyProfileStateModel) => state.updateStatus
  );

  export const getSettingFn = createSelector(
    [getCompanyProfile],
    (settings) => (settingCode: CompanyProfileSettingCode) =>
      settings?.[settingCode]
  );

  export const createSettingSelector = <K extends keyof CompanyProfileSetting>(
    settingCode: K
  ) => {
    return createSelector(
      [getCompanyProfile],
      (settings) => settings?.[settingCode]
    );
  };

  export const getThirdPartyEditPermission = createSelector(
    [UserProfileQueries.getUser, getCompanyProfile],
    (user, companyProfileSetting): boolean => {
      if (!user) return false;
      return (
        companyProfileSetting?.ThirdPartyRecordOption ===
          ThirdPartyRecordOption.AllowAll ||
        companyProfileSetting?.ThirdPartyRecordOption ===
          ThirdPartyRecordOption.ShowAll
      );
    }
  );

  export const getThirdPartySystemName = createSelector(
    [UserProfileQueries.getUser, getCompanyProfile],
    (user, companyProfileSetting) => {
      if (!companyProfileSetting || !user) {
        return undefined;
      }

      const ThirdPartyRecordOption =
        companyProfileSetting.ThirdPartyRecordOption;

      const generalReadAllowed =
        ThirdPartyRecordOption === 'AllowAll' ||
        ThirdPartyRecordOption === 'ShowAll';

      if (generalReadAllowed) {
        return companyProfileSetting.ThirdPartySystemName;
      }

      const userInstance = new User(user);

      const adminReadAllowed =
        ThirdPartyRecordOption === 'ShowAdminOnly' &&
        (userInstance.hasPermission('Company=SuperRead') ||
          userInstance.hasPermission('Contact=SuperRead'));

      if (adminReadAllowed) {
        return companyProfileSetting.ThirdPartySystemName;
      }

      return undefined;
    }
  );
}
