import type {
  AccessLevel,
  AccessPermission,
  Dictionary,
  Shareable,
} from '@cosmos/types-common';
import type { SearchFilter } from '@esp/common/types';

export enum ItemNameMappignOptions {
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
}

export interface QuickBooksIntegration
  extends QuickBooksAuthInfo,
    QuickBooksCompanyInfo,
    QuickBooksSettings {}

export interface QuickBooksAuthInfo {
  UpdatedBy?: string;
  UpdatedDate?: string;
  ExpirationDate?: string;
}

export interface QuickBooksCompanyInfo {
  HttpStatusCode?: string;
  CompanyName?: string;
  LegalName?: string;
  CompanyStartDate?: string;
  Country?: string;
}

export interface QuickBooksAPISettings extends Shareable {
  Id?: number;
  IsActive?: boolean;
  Type?: string;
  TransactionDetailAccessLevel?: AccessLevel;
  TransactionDetailAccess?: AccessPermission[];
  Settings?: {
    StatusCode: string;
    UIBaseUrl: string;
  };
  Data?: QuickBooksAPISettingsData;
}

export interface QuickBooksAPISettingsData {
  'Mapping:ItemName'?: ItemNameMappignOptions;
  'Option:SetCustomerAsBillable'?: boolean | string;
  'Option:SendInvoices'?: boolean | string;
  'Option:SendBills'?: boolean | string;
  'Option:SendPurchaseOrders'?: boolean | string;
  'Account:AccountsReceivable'?: string;
  'Account:Income'?: string;
  'Account:AccountsPayable'?: string;
  'Account:CostOfGoodsSold'?: string;
}

export interface QuickBooksSettings
  extends Omit<QuickBooksAPISettings, 'Data'> {
  Data?: QuickBooksSettingsData;
}

export interface QuickBooksSettingsData {
  ItemName?: ItemNameMappignOptions;
  SetCustomerAsBillable?: boolean;
  SendInvoices?: boolean;
  SendBills?: boolean;
  SendPurchaseOrders?: boolean;
  AccountsReceivable?: string;
  Income?: string;
  AccountsPayable?: string;
  CostOfGoodsSold?: string;
}

export interface CompanySearchFilters extends Dictionary<SearchFilter> {
  CompanyType: SearchFilter;
  Quickbooks: SearchFilter;
}

export enum QuickBooksCompaniesLinkingType {
  Customers = 'customer',
  Suppliers = 'supplier',
  Decorators = 'decorator',
}
