import { AfterSuccess } from '@cosmos/state';
import type { ProjectResolution } from '@esp/orders/types';
import type {
  AllowEditOrderNumberSetting,
  OrderStatusType,
  TenantOrderNumber,
} from '@esp/settings/types';

const ACTION_SCOPE = '[OrderSettings]' as const;

export namespace OrderSettingsActions {
  export class LoadUsers {
    static type = `${ACTION_SCOPE}  Load Users`;
  }

  @AfterSuccess(OrderSettingsActions.LoadUsers)
  export class LoadOrderNumber {
    static type = `${ACTION_SCOPE} Load Order Number Setting`;
  }

  @AfterSuccess(OrderSettingsActions.LoadOrderNumber)
  export class UpdateOrderNumberSetting {
    static type = `${ACTION_SCOPE} Update Order Number Setting`;

    constructor(public payload: TenantOrderNumber) {}
  }

  export class LoadAllowEditOrderNumber {
    static type = `${ACTION_SCOPE} Load Allow Edit Order Number`;
  }

  @AfterSuccess(OrderSettingsActions.LoadAllowEditOrderNumber)
  export class UpdateAllowEditOrderNumber {
    static type = `${ACTION_SCOPE} Update Allow Edit Order Number`;

    constructor(public payload: AllowEditOrderNumberSetting) {}
  }

  export class LoadOrderStatus {
    static type = `${ACTION_SCOPE} Load Order Status`;
  }

  @AfterSuccess(OrderSettingsActions.LoadOrderStatus)
  export class UpdateOrderStatus {
    static type = `${ACTION_SCOPE} Update Order Status`;

    constructor(public payload: OrderStatusType) {}
  }

  @AfterSuccess(OrderSettingsActions.LoadOrderStatus)
  export class CreateOrderStatus {
    static type = `${ACTION_SCOPE} Create Order Status`;

    constructor(public payload: OrderStatusType) {}
  }

  @AfterSuccess(OrderSettingsActions.LoadOrderStatus)
  export class RemoveOrderStatus {
    static type = `${ACTION_SCOPE} Remove Order Status`;

    constructor(public payload: OrderStatusType) {}
  }

  export class LoadResolutions {
    static type = `${ACTION_SCOPE} Load Resolutions`;
  }

  @AfterSuccess(OrderSettingsActions.LoadResolutions)
  export class CreateResolution {
    static type = `${ACTION_SCOPE} Create Resolutions`;

    constructor(public payload: ProjectResolution) {}
  }

  @AfterSuccess(OrderSettingsActions.LoadResolutions)
  export class UpdateResolutions {
    static type = `${ACTION_SCOPE} Update Resolutions`;

    constructor(public payload: ProjectResolution) {}
  }

  @AfterSuccess(OrderSettingsActions.LoadResolutions)
  export class RemoveResolutions {
    static type = `${ACTION_SCOPE} Remove Resolutions`;

    constructor(public payload: number) {}
  }
}
