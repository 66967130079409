import type { SearchCriteria } from '@esp/common/types';
import type {
  QuickBooksAPISettingsData,
  QuickBooksCompaniesLinkingType,
  QuickBooksSettings,
} from '@esp/quickbooks/types';

const ACTION_SCOPE = '[QuickBooksIntegration]' as const;

export namespace QuickBooksIntegrationActions {
  export class LoadQuickBooksIntegrationFullData {
    static readonly type = `${ACTION_SCOPE} Load QuickBooks Integration Full Data`;
  }

  export class LoadQuickBooksIntegration {
    static readonly type = `${ACTION_SCOPE} Load QuickBooks Integration`;

    constructor(public withCompanyInfo = false) {}
  }

  export class UninstallQuickBooksIntegration {
    static readonly type = `${ACTION_SCOPE} Uninstall QuickBooks Integration`;
  }

  export class LoadQuickBooksAccounts {
    static readonly type = `${ACTION_SCOPE} Load QuickBooks Accounts`;

    constructor(public payload: SearchCriteria) {}
  }

  export class LoadQuickBooksTaxOptions {
    static readonly type = `${ACTION_SCOPE} Load QuickBooks Tax Options`;

    constructor(public payload: SearchCriteria) {}
  }

  export class UpdateQuickBooksIntegrationSettingsData {
    static readonly type = `${ACTION_SCOPE} Update QuickBooks Integration Settings Data`;

    constructor(public payload: Partial<QuickBooksAPISettingsData>) {}
  }

  export class UpdateQuickBooksIntegrationSettings {
    static readonly type = `${ACTION_SCOPE} Update QuickBooks Integration Settings`;

    constructor(public payload: Partial<QuickBooksSettings>) {}
  }

  export class SearchQuickBooksCompanyRecords {
    static type = `${ACTION_SCOPE} Search QuickBooks Company Records`;

    constructor(
      public terms: string,
      public type: QuickBooksCompaniesLinkingType
    ) {}
  }
}
