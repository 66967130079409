export enum PaymentProviderPrefix {
  Stripe = 'stripe',
  Authorizenet = 'authorize_net',
  PromoPayment = 'promopayment',
}

export enum PaymentProvidersSettingsType {
  PayPalAccountId = 'payment_providers.paypal.account_id',
  StripeAccountId = 'payment_providers.stripe.account_id',
  StripePublicClientKey = 'payment_providers.stripe.public_client_key',
  StripeSecretKey = 'payment_providers.stripe.secret_key',
  StripeAcceptsCreditCards = 'payment_providers.stripe.accepts_credit_card',
  AuthorizenetPublicClientKey = 'payment_providers.authorize_net.public_client_key',
  AuthorizenetApiLoginKey = 'payment_providers.authorize_net.api_login_key',
  AuthorizenetTransactionKey = 'payment_providers.authorize_net.transaction_key',
  AuthorizenetAcceptsCreditCards = 'payment_providers.authorize_net.accepts_credit_card',
  AuthorizenetElectronicFundsTransfer = 'payment_providers.authorize_net.EFT',
  PromoPaymentSecurityKey = 'payment_providers.promopayment.security_key',
  PromoPaymentPublicKey = 'payment_providers.promopayment.public_key',
  PromoPaymentAcceptsCreditCards = 'payment_providers.promopayment.accepts_credit_card',
  PromoPaymentElectronicFundsTransfer = 'payment_providers.promopayment.EFT',
  PortalDefault = 'payment_providers.default',
}

/**
 * Stripe fields that are going to be used in provider settings form.
 *
 * These keys will be reset every time on "provider disconnected" action
 */
export const StripeTypes = [
  PaymentProvidersSettingsType.StripePublicClientKey,
  PaymentProvidersSettingsType.StripeSecretKey,
  PaymentProvidersSettingsType.StripeAccountId,
];

/**
 * Authorizenet fields that are going to be used in provider settings form.
 *
 * These keys will be reset every time on "provider disconnected" action
 */
export const AuthorizenetTypes = [
  PaymentProvidersSettingsType.AuthorizenetPublicClientKey,
  PaymentProvidersSettingsType.AuthorizenetApiLoginKey,
  PaymentProvidersSettingsType.AuthorizenetTransactionKey,
  PaymentProvidersSettingsType.AuthorizenetElectronicFundsTransfer,
];

/**
 * PromoPayment fields that are going to be used on provider settings form.
 *
 * These keys will be reset every time on "provider disconnected" action
 */
export const PromoPaymentTypes = [
  PaymentProvidersSettingsType.PromoPaymentSecurityKey,
  PaymentProvidersSettingsType.PromoPaymentPublicKey,
  PaymentProvidersSettingsType.PromoPaymentElectronicFundsTransfer,
];

export interface PaymentMethodInfo {
  Logo?: string;
  Name: string;
  Id: string;
}

export interface PayPalSettings {
  AccountId: string;
}

export type PaymentSettings = Partial<
  PromoPaymentSettings & AuthorizenetSettings & StripeSettings
>;

export interface PromoPaymentSettings {
  PublicKey: string;
  PrivateKey: string;
  AcceptsCreditCards: boolean;
  ElectronicFundsTransferAcceptance: boolean;
  IsPortalDefault: boolean;
}

export interface StripeSettings {
  AccountId: string;
  PublicKey: string;
  PrivateKey: string;
  AcceptsCreditCards: boolean;
  IsPortalDefault: boolean;
}

export interface AuthorizenetSettings {
  PublicClientKey: string;
  ApiLoginKey: string;
  TransactionKey: string;
  AcceptsCreditCards: boolean;
  ElectronicFundsTransferAcceptance: boolean;
  IsPortalDefault: boolean;
}

export enum PaymentProviderName {
  Authorizenet = 'AuthorizeNet',
  PromoPayment = 'PromoPayment',
  Stripe = 'Stripe',
}

export enum Passwords {
  Asterisk = '********',
  Dots = '••••••••',
}
