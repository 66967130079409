import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import { searchCriteriaToHttpParams } from '@esp/common/util-http';
import type {
  GetQuickBooksPartyPayload,
  GetQuickBooksPartySearchPayload,
  LinkQuickBooksPayload,
  PullCompanyDetailsFromQuickBooksPayload,
  PushCompanyDetailsToQuickBooksPayload,
  PushOrderItemsToQuickBooksPayload,
  PushOrderItemsToQuickBooksStatus,
  QuickBooksCompany,
  QuickBooksPartySearch,
  UnlinkQuickBooksPayload,
} from '@esp/quickbooks/types';

@Injectable({
  providedIn: 'root',
})
export class QuickBooksApiService {
  private readonly _apiUrl = `${inject(ConfigService).get(
    'espServiceApiUrl'
  )}/integrations/qbo`;

  constructor(private readonly _http: HttpClient) {}

  getQuickBooksParty(
    payload: GetQuickBooksPartyPayload
  ): Observable<QuickBooksCompany> {
    return this._http.get<QuickBooksCompany>(
      `${this._apiUrl}/records/${payload.type}/${payload.partyId}`
    );
  }

  link(payload: LinkQuickBooksPayload): Observable<null> {
    return this._http.put<null>(
      `${this._apiUrl}/companies/${payload.Id}/linkto/${payload.LinkToId}`,
      {}
    );
  }

  pullCompanyDetailsFromQuickBooks(
    payload: PullCompanyDetailsFromQuickBooksPayload
  ): Observable<null> {
    return this._http.post<null>(
      `${this._apiUrl}/companies/${payload.companyId}/pull`,
      {},
      {
        params: {
          companyRole: payload.companyRole,
        },
      }
    );
  }

  pushCompanyDetailsToQuickBooks(
    payload: PushCompanyDetailsToQuickBooksPayload
  ): Observable<string> {
    return this._http.post<string>(
      `${this._apiUrl}/companies/${payload.companyId}/push`,
      {},
      {
        params: {
          companyRole: payload.companyRole,
        },
      }
    );
  }

  pushOrderItemsToQuickBooks(
    payload: PushOrderItemsToQuickBooksPayload
  ): Observable<PushOrderItemsToQuickBooksStatus[]> {
    return payload.items.length > 1
      ? this._http.post<PushOrderItemsToQuickBooksStatus[]>(
          `${this._apiUrl}/orders/${payload.orderId}/pushbulk`,
          payload.items
        )
      : this._http.post<PushOrderItemsToQuickBooksStatus[]>(
          `${this._apiUrl}/orders/${payload.orderId}/push`,
          payload.items[0]
        );
  }

  unlink(payload: UnlinkQuickBooksPayload): Observable<null> {
    return this._http.put<null>(
      `${this._apiUrl}/companies/${payload.Id}/linkto/0`,
      {}
    );
  }

  search(
    payload: GetQuickBooksPartySearchPayload
  ): Observable<QuickBooksPartySearch[]> {
    const params = searchCriteriaToHttpParams(payload.searchCriteria);

    return this._http.get<QuickBooksPartySearch[]>(
      `${this._apiUrl}/records/${payload.searchCriteria.type}/search`,
      {
        params,
      }
    );
  }
}
