import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type { CompanyProfileSetting } from '@esp/settings/types';

@Injectable({
  providedIn: 'root',
})
export class CompanyProfileService {
  private _url = 'settings/companyprofile';

  private get _uri() {
    const espServiceApiUrl = this._configService.get('espServiceApiUrl');
    return `${espServiceApiUrl}/${this._url}`;
  }

  constructor(
    private readonly _configService: ConfigService,
    private readonly _http: HttpClient
  ) {}

  get(): Observable<CompanyProfileSetting> {
    return this._http.get<CompanyProfileSetting>(this._uri);
  }

  update(payload: CompanyProfileSetting): Observable<CompanyProfileSetting> {
    return this._http.put<CompanyProfileSetting>(this._uri, payload);
  }
}
