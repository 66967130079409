import { NOT_SET, type Nullable } from '@cosmos/types-common';
import type { CompanyProfileSetting } from '@esp/settings/types';

/**
 * See its usages. This is used to set the `CompanyProfile` context when
 * compiling email templates.
 */
export function getCompanyProfileForEmail(
  companyProfile: Nullable<CompanyProfileSetting>,
  personalSignature: Nullable<string>
) {
  return companyProfile
    ? {
        ...companyProfile,
        Signature:
          (companyProfile?.AllowCustomSignature
            ? personalSignature
              ? personalSignature
              : NOT_SET
            : companyProfile?.Signature) ?? NOT_SET,
      }
    : NOT_SET;
}
