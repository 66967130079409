import { map, of } from 'rxjs';

import type { Collaborator } from '@cosmos/types-common';
import type { AutocompleteService } from '@esp/autocomplete/data-access-api';
import type {
  QuickBooksAPISettingsData,
  QuickBooksSettingsData,
} from '@esp/quickbooks/types';
import { RoleCode } from '@esp/settings/types';

import type { IntegrationSettings } from '../models';

export function mapToQBOSettingsData(
  data: QuickBooksAPISettingsData
): QuickBooksSettingsData {
  return {
    ItemName: data['Mapping:ItemName'],
    SetCustomerAsBillable:
      data['Option:SetCustomerAsBillable'] === 'true' ||
      data['Option:SetCustomerAsBillable'] === true,
    SendInvoices:
      data['Option:SendInvoices'] === 'true' ||
      data['Option:SendInvoices'] === true,
    SendBills:
      data['Option:SendBills'] === 'true' || data['Option:SendBills'] === true,
    SendPurchaseOrders:
      data['Option:SendPurchaseOrders'] === 'true' ||
      data['Option:SendPurchaseOrders'] === true,
    AccountsReceivable: data['Account:AccountsReceivable'],
    Income: data['Account:Income'],
    AccountsPayable: data['Account:AccountsPayable'],
    CostOfGoodsSold: data['Account:CostOfGoodsSold'],
  };
}

export function mapToQBOSettingsAPIData(
  data: QuickBooksSettingsData
): QuickBooksAPISettingsData {
  return {
    'Mapping:ItemName': data.ItemName,
    'Option:SetCustomerAsBillable': data.SetCustomerAsBillable,
    'Option:SendInvoices': data.SendInvoices,
    'Option:SendBills': data.SendBills,
    'Option:SendPurchaseOrders': data.SendPurchaseOrders,
    'Account:AccountsReceivable': data.AccountsReceivable,
    'Account:Income': data.Income,
    'Account:AccountsPayable': data.AccountsPayable,
    'Account:CostOfGoodsSold': data.CostOfGoodsSold,
  };
}

export function getIntegrationSettingsWithFullCollaboratorsData<
  T extends IntegrationSettings,
>(
  integrationSettings: T | null,
  autocompleteService: AutocompleteService,
  userId?: number
) {
  // It may be `null` when QuickBooks is disconnected from the integration settings.
  integrationSettings ||= <T>{};

  const users: string[] = [
    ...(integrationSettings.Access
      ? integrationSettings.Access.filter(
          (access) => access.AccessLevel === 'User'
        ).map((access) => (access.EntityId ? access.EntityId.toString() : ''))
      : [userId?.toString() ?? '0']),
    ...(integrationSettings.TransactionDetailAccess
      ? integrationSettings.TransactionDetailAccess.filter(
          (access) => access.AccessLevel === 'User'
        ).map((access) => (access.EntityId ? access.EntityId.toString() : ''))
      : []),
  ];

  return (integrationSettings.AccessLevel === 'Custom' &&
    integrationSettings.Access &&
    integrationSettings.Access.length) ||
    (integrationSettings.TransactionDetailAccessLevel === 'Custom' &&
      integrationSettings.TransactionDetailAccess &&
      integrationSettings.TransactionDetailAccess.length) ||
    userId
    ? autocompleteService
        .usersAndTeams({
          term: '',
          filters: {
            Ids: {
              Terms: users,
              Behavior: 'any',
            },
          },
        })
        .pipe(
          map((usersAndTeams) => ({
            ...integrationSettings,
            Collaborators: usersAndTeams.map((team) => ({
              ...team,
              AccessType:
                integrationSettings.Access?.find((e) => e.EntityId === team.Id)
                  ?.AccessType ?? 'ReadWrite',
              Role: team.Roles?.find(
                (role) => role.Code === RoleCode.Administrator
              )
                ? RoleCode.Administrator
                : RoleCode.User,
            })) as Collaborator[],
          }))
        )
    : of(integrationSettings);
}
