import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type { Setting } from '@esp/settings/types';

@Injectable({
  providedIn: 'root',
})
export class PaymentProvidersSettingsService {
  private readonly _apiUrl: string =
    inject(ConfigService).get('espServiceApiUrl');

  constructor(private readonly _http: HttpClient) {}

  get(): Observable<Setting[]> {
    return this._http.get<Setting[]>(
      this._apiUrl + '/settings/paymentproviders'
    );
  }

  updateSettingBulk(setting: Setting[]): Observable<Setting[]> {
    return this._http.put<Setting[]>(`${this._apiUrl}/settings/bulk`, setting);
  }

  retrieveOAuthLink(): Observable<string> {
    return this._http.get<string>(
      `${this._apiUrl}/paymentproviders/stripe/oauth-step-1`
    );
  }

  connectStripeAccount(code: string): Observable<string> {
    return this._http.get<string>(
      `${this._apiUrl}/paymentproviders/stripe/oauth-step-2?code=${code}`
    );
  }
}
