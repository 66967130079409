// Defining different options or settings related to
// handling third-party records or data.
export enum ThirdPartyRecordOption {
  // Indicates that all third-party records should be hidden or not displayed.
  HideAll = 'HideAll',
  // Suggests displaying all third-party records, regardless of any filtering criteria.
  ShowAll = 'ShowAll',
  // Only records relevant to administrators or authorized personnel should be shown.
  ShowAdminOnly = 'ShowAdminOnly',
  // Allowing access or display of all third-party records, possibly without any restrictions.
  AllowAll = 'AllowAll',
}
