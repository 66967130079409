export enum SecuritySettingCode {
  CanEdit = 'security_defaults.canEdit',
  Companies = 'security_defaults.company',
  Projects = 'security_defaults.project',
  Tasks = 'security_defaults.task',
  Contacts = 'security_defaults.contact',
  Collections = 'security_defaults.collection',
  Websites = 'security_defaults.website',
  Stores = 'security_defaults.store',
}

export enum UserAccessRule {
  ReadOnly = 'R',
  ReadAndEdit = 'RE',
}
