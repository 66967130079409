import type {
  ToastData,
  ToastType,
} from '@cosmos/types-notification-and-toast';
import { LanguageScope } from '@cosmos/util-translations';
import type { OrderType } from '@esp/orders/types';
import {
  SecuritySettingCode,
  User,
  type DocumentTypeSetting,
  type Setting,
} from '@esp/settings/types';

import { getDocumentTypeName } from '../utils/design-settings.utils';

const getTitleKey = (key: string) => `espSettings.toast-messages.${key}.title`;
const getBodyKey = (key: string) => `espSettings.toast-messages.${key}.body`;

const getToastData = (
  key: string,
  type: ToastType,
  interpolationParams?: Record<string, unknown>,
  hide?: { title?: boolean; body?: boolean }
): ToastData => {
  const data = {
    title: hide?.title ? undefined : getTitleKey(key),
    body: hide?.body ? undefined : getBodyKey(key),
    type,
    languageScope: LanguageScope.EspSettings,
  };

  return interpolationParams ? { ...data, interpolationParams } : data;
};

export const TOAST_MESSAGES = {
  DOCUMENT_TYPE_SETTINGS_NOT_UPDATED: ({
    DocumentType,
  }: DocumentTypeSetting): ToastData =>
    getToastData('document-type-settings-not-updated', 'error', {
      documentTypeName: getDocumentTypeName(DocumentType),
    }),
  USE_COMPANY_LOGO_NOT_UPDATED: (): ToastData =>
    getToastData('company-logo-not-updated', 'error'),
  TASK_NOT_CREATED: (): ToastData => getToastData('task-not-created', 'error'),
  TASK_NOT_UPDATED: (taskName: string): ToastData =>
    getToastData('task-not-updated', 'error', { taskName }),
  TASK_NOT_REMOVED: (): ToastData => getToastData('task-not-removed', 'error'),
  UPDATE_SIGNATURE_ERROR: (): ToastData =>
    getToastData('update-signature-error', 'error'),
  UPDATE_COMPANY_PROFILE_SETTINGS_ERROR: (): ToastData =>
    getToastData('update-company-profile-setting-error', 'error'),
  UPDATE_PRIVACY_SETTING: (payload: Setting): ToastData => {
    if (payload.Type === SecuritySettingCode.CanEdit) {
      return getToastData('update-privacy-setting-can-edit', 'confirm');
    } else if (payload.Type === SecuritySettingCode.Tasks) {
      return getToastData('update-privacy-setting-task', 'confirm', {
        value: payload.Value === 'Everyone' ? 'Public' : 'Private',
      });
    } else {
      return getToastData('update-privacy-setting-other', 'confirm', {
        type: Object.keys(SecuritySettingCode)[
          Object.values(SecuritySettingCode).indexOf(
            payload.Type as SecuritySettingCode
          )
        ],
        value: payload.Value === 'Owner' ? 'Owner Only' : payload.Value,
      });
    }
  },
  UPDATE_PRIVACY_SETTING_ERROR: (payload: Setting): ToastData => {
    if (payload.Type === SecuritySettingCode.CanEdit) {
      return getToastData('update-privacy-setting-error-can-edit', 'error');
    } else if (payload.Type === SecuritySettingCode.Tasks) {
      return getToastData('update-privacy-setting-error-task', 'error', {
        value: payload.Value === 'Everyone' ? 'Public' : 'Private',
      });
    } else {
      return getToastData('update-privacy-setting-error-other', 'error', {
        type: Object.keys(SecuritySettingCode)[
          Object.values(SecuritySettingCode).indexOf(
            payload.Type as SecuritySettingCode
          )
        ],
        value: payload.Value === 'Owner' ? 'Owner Only' : payload.Value,
      });
    }
  },
  CREDENTIALS_UPDATE_SUCCESS: (supplierName: string): ToastData =>
    getToastData('credential-update-success', 'confirm', { supplierName }),
  CREDENTIALS_UPDATE_FAIL: (): ToastData =>
    getToastData('credential-update-fail', 'error'),
  CREDENTIALS_UPDATE_UNAUTHORIZED: (): ToastData =>
    getToastData('credential-update-unauthorized', 'error'),
  SMARTBOOKS_NOT_SAVED: (): ToastData =>
    getToastData('smartbooks-not-saved', 'error'),
  QUICKBOOKS_NOT_LOADED: (): ToastData =>
    getToastData('quickbooks-not-loaded', 'error'),
  QUICKBOOKS_NOT_UNISTALLED: (): ToastData =>
    getToastData('quickbooks-not-uninstalled', 'error'),
  QUICKBOOKS_NOT_SAVED: (): ToastData =>
    getToastData('quickbooks-not-saved', 'error'),
  ALLOW_USER_OVERRIDES_FAIL: (): ToastData =>
    getToastData('allow-user-override-fail', 'error'),
  EDIT_STARTING_NUMBER_FAIL: (): ToastData =>
    getToastData('edit-starting-number-fail', 'error'),
  CREATE_STATUS_TYPE_FAIL: (): ToastData =>
    getToastData('create-status-type-fail', 'error', undefined, {
      body: true,
    }),
  UPDATE_STATUS_TYPE_FAIL: (statusName: string): ToastData =>
    getToastData(
      'update-status-type-fail',
      'error',
      { statusName },
      {
        body: true,
      }
    ),
  DELETE_STATUS_TYPE_FAIL: (statusName: string): ToastData =>
    getToastData(
      'delete-status-type-fail',
      'error',
      { statusName },
      {
        body: true,
      }
    ),
  DELETE_RESOLUTION_FAIL: (): ToastData =>
    getToastData('delete-resolution-fail', 'error'),

  DEACTIVATE_USER_SUCCESS: (): ToastData =>
    getToastData('deactivate-user-success', 'confirm', undefined, {
      title: true,
    }),
  DEACTIVATE_USER_ERROR: (): ToastData =>
    getToastData('deactivate-user-error', 'error', undefined, {
      title: true,
    }),
  ASSIGN_LICENSE_SUCCESS: (user: User): ToastData =>
    getToastData('assign-licence-success', 'confirm', { userName: user.Name }),
  ASSIGN_LICENSE_ERROR: (): ToastData =>
    getToastData('assign-licence-error', 'error', undefined, {
      title: true,
    }),
  UPDATE_ROLES_SUCCESS: (): ToastData =>
    getToastData('update-roles-success', 'confirm', undefined, {
      title: true,
    }),
  UPDATE_ROLES_ERROR: (): ToastData =>
    getToastData('update-roles-error', 'error', undefined, {
      title: true,
    }),
  SEARCH_EXTERNAL_ERROR: (): ToastData =>
    getToastData('search-external-error', 'error', undefined, {
      title: true,
    }),
  ADD_USER_SUCCESS: (): ToastData =>
    getToastData('add-user-success', 'confirm', undefined, {
      title: true,
    }),
  ADD_USER_ERROR: (): ToastData =>
    getToastData('add-user-error', 'error', undefined, {
      title: true,
    }),
  GET_LICENSE_INFO_ERROR: (): ToastData =>
    getToastData('get-licence-info-error', 'error', undefined, {
      title: true,
    }),
  CONNECT_ERROR: (): ToastData =>
    getToastData('cannot-connect', 'error', undefined),
  DISCONNECT_ERROR: (): ToastData =>
    getToastData('cannot-disconnect', 'error', undefined),
  CONNECT_PROVIDER: (provider: string): ToastData =>
    getToastData('connect-provider', 'confirm', { provider }),
  DISCONNECT_PROVIDER: (provider: string): ToastData =>
    getToastData('disconnect-provider', 'confirm', { provider }),
  LINKING_ERROR: (): ToastData =>
    getToastData('linking-error', 'error', undefined),
  UPDATE_TENANT_VALIDATION_LIST_SUCCESS: (orderType: OrderType): ToastData =>
    getToastData(
      'update-tenant-validation-list-success.' + orderType,
      'confirm',
      undefined
    ),
  UPDATE_TENANT_VALIDATION_LIST_ERROR: (orderType: OrderType): ToastData =>
    getToastData(
      'update-tenant-validation-list-error.' + orderType,
      'error',
      undefined
    ),
  TEAM_NAME_CONFLICT_ERROR: (name: string): ToastData =>
    getToastData('team-name-conflict', 'error', { name }),

  TEAM_CREATION_SUCCESS: (name: string): ToastData =>
    getToastData('team-creation-success', 'confirm', { name }),

  TEAM_CREATION_ERROR: (name: string): ToastData =>
    getToastData('team-creation-error', 'error', { name }),

  TEAM_UPDATE_SUCCESS: (name: string): ToastData =>
    getToastData('update-team-success', 'confirm', { name }),

  TEAM_UPDATE_ERROR: (name: string): ToastData =>
    getToastData('update-team-error', 'error', { name }),
  TEAM_DELETE_SUCCESS: (name: string): ToastData =>
    getToastData('delete-team-success', 'confirm', { name }),
  TEAM_DELETE_ERROR: (name: string): ToastData =>
    getToastData('delete-team-error', 'error', { name }),
  TEAM_DELETE_CONFLICT_ERROR: (name: string): ToastData =>
    getToastData('delete-team-conflict-error', 'error', { name }),
};
