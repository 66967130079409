import { createPropertySelectors, createSelector } from '@ngxs/store';

import { createLoadingSelectorsFor } from '@cosmos/state';
import type { OrderStatusType } from '@esp/settings/types';

import { OrderSettingsState, type OrderSettingsStateModel } from '../states';

export namespace OrderSettingsQueries {
  export const { isLoading, hasLoaded, getLoadError } =
    createLoadingSelectorsFor<OrderSettingsStateModel>(OrderSettingsState);

  export const {
    OrderNumber,
    AllowEditOrderNumber,
    OrderStatusType,
    ProjectResolutions,
    users,
  } = createPropertySelectors<OrderSettingsStateModel>(OrderSettingsState);

  export const orderStatusesColorsMap = createSelector(
    [OrderStatusType],
    (orderStatuses: OrderStatusType[]) => {
      return orderStatuses?.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.Label]: curr.Color,
        };
      }, {});
    }
  );
}
