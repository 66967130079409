import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Action,
  Actions,
  ofActionDispatched,
  State,
  Store,
  type StateContext,
} from '@ngxs/store';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Auth, AuthTokenService } from '@asi/auth/data-access-auth';
import { syncLoadProgress, syncOperationProgress } from '@cosmos/state';
import {
  getDefaultOperationStatus,
  type ModelWithLoadingStatus,
  type OperationStatus,
} from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { injectAppDestroyRef } from '@cosmos/util-app-destroy-ref';
import { AutocompleteService } from '@esp/autocomplete/data-access-api';
import { QuickBooksApiService } from '@esp/quickbooks/data-access';
import {
  QuickBooksCompaniesLinkingType,
  type QuickBooksAPISettings,
  type QuickBooksAPISettingsData,
  type QuickBooksIntegration,
  type QuickBooksPartySearch,
  type QuickBooksTaxOption,
} from '@esp/quickbooks/types';

import { QuickBooksIntegrationActions } from '../actions';
import { QuickBooksIntegrationService } from '../services';
import {
  getIntegrationSettingsWithFullCollaboratorsData,
  mapToQBOSettingsAPIData,
  mapToQBOSettingsData,
} from '../utils';

import { TOAST_MESSAGES } from './toast-messages';

export interface QuickBooksIntegrationStateModel
  extends ModelWithLoadingStatus {
  quickBooksIntegration: QuickBooksIntegration;
  updateQuickBooksOperation?: OperationStatus;
  quickBooksAccounts: QuickBooksPartySearch[];
  quickBooksAccountsLoading: OperationStatus;
  quickBooksTaxOptions: QuickBooksTaxOption[];
  quickBooksCompanyRecords: QuickBooksPartySearch[];
}

const getDefaultState = (): QuickBooksIntegrationStateModel => ({
  quickBooksIntegration: {},
  quickBooksAccounts: [],
  quickBooksAccountsLoading: getDefaultOperationStatus(),
  quickBooksTaxOptions: [],
  quickBooksCompanyRecords: [],
});

type LocalStateContext = StateContext<QuickBooksIntegrationStateModel>;

namespace InternalActions {
  export class LoadQuickBooksIntegration {
    static readonly type =
      '[QuickBooksIntegration] Internal Load QuickBooks Integration';

    constructor(readonly withCompanyInfo = false) {}
  }
}

@State<QuickBooksIntegrationStateModel>({
  name: 'quickBooksIntegrationSettings',
  defaults: getDefaultState(),
})
@Injectable()
export class QuickBooksIntegrationState {
  private readonly _appDestroyRef = injectAppDestroyRef();

  constructor(
    private _service: QuickBooksIntegrationService,
    private _quickBooksApiService: QuickBooksApiService,
    private readonly _autocompleteService: AutocompleteService,
    private readonly _authToken: AuthTokenService
  ) {
    const store = inject(Store);
    const actions$ = inject(Actions);

    actions$
      .pipe(
        ofActionDispatched(
          QuickBooksIntegrationActions.LoadQuickBooksIntegration
        ),
        // The `QuickBooksIntegrationActions.LoadQuickBooksIntegration` might be dispatched
        // multiple times which will cause multiple HTTP requests being done simultaneously.
        // The exhaust is used to ignore any incoming actions if the actual load is already
        // being handled.
        exhaustMap(
          (action: QuickBooksIntegrationActions.LoadQuickBooksIntegration) =>
            store.dispatch(
              new InternalActions.LoadQuickBooksIntegration(
                action.withCompanyInfo
              )
            )
        ),
        takeUntilDestroyed(injectAppDestroyRef())
      )
      .subscribe();
  }

  ngxsOnInit(ctx: LocalStateContext) {
    this._authToken
      .getToken()
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this._appDestroyRef))
      .subscribe((token) => {
        if (token) {
          ctx.dispatch(
            new QuickBooksIntegrationActions.LoadQuickBooksIntegration()
          );
        }
      });
  }

  @Action(InternalActions.LoadQuickBooksIntegration)
  private _loadQuickBooksIntegration(
    ctx: LocalStateContext,
    { withCompanyInfo }: QuickBooksIntegrationActions.LoadQuickBooksIntegration
  ) {
    return this._service.getQuickBooksSettings().pipe(
      switchMap((settings) =>
        forkJoin([
          getIntegrationSettingsWithFullCollaboratorsData<QuickBooksAPISettings>(
            settings,
            this._autocompleteService
          ),
          withCompanyInfo && settings?.Id
            ? this._service.getQuickBooksCompanyInfo()
            : of(undefined),
        ])
      ),
      tap(([settings, companyInfo]) => {
        ctx.patchState({
          quickBooksIntegration: {
            ...settings,
            ...companyInfo,
            Data: settings?.Data
              ? mapToQBOSettingsData(settings.Data)
              : undefined,
          },
        });
      }),
      syncLoadProgress(ctx)
    );
  }

  @Action(QuickBooksIntegrationActions.LoadQuickBooksIntegrationFullData)
  private _loadQuickBooksIntegrationFullData(ctx: LocalStateContext) {
    return this._service.getQuickBooksSettings().pipe(
      //ENCORE-44385 for new installation, we need to PUT default sending values
      switchMap((settings) => this._updateDefaultSendingOptions(settings)),
      switchMap((settings) =>
        getIntegrationSettingsWithFullCollaboratorsData<QuickBooksAPISettings>(
          settings,
          this._autocompleteService
        )
      ),
      switchMap((settings) => {
        if (settings.Id) {
          return combineLatest([
            this._service.getQuickBooksAuthInfo(),
            this._service.getQuickBooksCompanyInfo(),
            of(settings),
          ]);
        } else return of([undefined, undefined, settings]);
      }),
      tap(([authinfo, companyInfo, settings]) => {
        ctx.patchState({
          quickBooksIntegration: {
            ...authinfo,
            ...companyInfo,
            ...settings,
            Data: settings?.Data
              ? mapToQBOSettingsData(settings.Data)
              : undefined,
          },
        });
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.QUICKBOOKS_NOT_LOADED())
        );
        return EMPTY;
      }),
      syncLoadProgress(ctx)
    );
  }

  @Action(QuickBooksIntegrationActions.UninstallQuickBooksIntegration)
  private _uninstallQuickBooksIntegration(ctx: LocalStateContext) {
    return this._service.uninstallQuickBooks().pipe(
      tap(() => {
        ctx.patchState({
          quickBooksIntegration: {
            Id: 0,
          },
        });
        ctx.dispatch(new Auth.CheckSession());
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.QUICKBOOKS_NOT_UNISTALLED())
        );
        return EMPTY;
      }),
      syncLoadProgress(ctx)
    );
  }

  @Action(QuickBooksIntegrationActions.LoadQuickBooksAccounts)
  private _loadQuickBooksAccounts(
    ctx: LocalStateContext,
    { payload }: QuickBooksIntegrationActions.LoadQuickBooksAccounts
  ) {
    return this._service.getQuickBooksAccounts(payload).pipe(
      syncOperationProgress(ctx, 'quickBooksAccountsLoading'),
      tap((quickBooksAccounts) => {
        ctx.patchState({
          quickBooksAccounts,
        });
      }),
      catchError(() => {
        //@TODO add QBO error toasts
        return EMPTY;
      })
    );
  }

  @Action(QuickBooksIntegrationActions.LoadQuickBooksTaxOptions)
  private _loadQuickBooksTaxOptions(
    ctx: LocalStateContext,
    { payload }: QuickBooksIntegrationActions.LoadQuickBooksTaxOptions
  ) {
    return this._service.getQuickBooksTaxOptions(payload).pipe(
      tap((quickBooksTaxOptions) => {
        ctx.patchState({
          quickBooksTaxOptions,
        });
      }),
      catchError(() => {
        return EMPTY;
      })
    );
  }

  @Action(QuickBooksIntegrationActions.UpdateQuickBooksIntegrationSettingsData)
  private _updateQuickBooksIntegrationSettingsData(
    ctx: LocalStateContext,
    {
      payload,
    }: QuickBooksIntegrationActions.UpdateQuickBooksIntegrationSettingsData
  ) {
    const quickBooksIntegration = ctx.getState().quickBooksIntegration;
    return this._service
      .updateQuickBooksIntegrationSettings({
        ...quickBooksIntegration,
        Data: {
          ...quickBooksIntegration.Data,
          ...payload,
        },
      })
      .pipe(
        switchMap((settings) =>
          getIntegrationSettingsWithFullCollaboratorsData<QuickBooksAPISettings>(
            settings,
            this._autocompleteService
          )
        ),
        tap((quickBooksAPISettings) => {
          ctx.patchState({
            quickBooksIntegration: {
              ...ctx.getState().quickBooksIntegration,
              ...quickBooksAPISettings,
              Data: mapToQBOSettingsData(
                quickBooksAPISettings.Data as QuickBooksAPISettingsData
              ),
            },
          });
        }),
        catchError(() => {
          //@TODO add QBO error toasts
          return EMPTY;
        })
      );
  }

  @Action(QuickBooksIntegrationActions.UpdateQuickBooksIntegrationSettings)
  private _updateQuickBooksIntegrationSettings(
    ctx: LocalStateContext,
    {
      payload,
    }: QuickBooksIntegrationActions.UpdateQuickBooksIntegrationSettings
  ) {
    const quickBooksIntegration = ctx.getState().quickBooksIntegration;

    return this._service
      .updateQuickBooksIntegrationSettings({
        ...quickBooksIntegration,
        ...payload,
        Data: mapToQBOSettingsAPIData(
          payload.Data
            ? payload.Data
            : quickBooksIntegration.Data
              ? quickBooksIntegration.Data
              : {}
        ),
      })
      .pipe(
        syncOperationProgress(ctx, 'updateQuickBooksOperation'),
        switchMap((settings) =>
          getIntegrationSettingsWithFullCollaboratorsData<QuickBooksAPISettings>(
            settings,
            this._autocompleteService
          )
        ),
        tap((quickBooksAPISettings) => {
          ctx.patchState({
            quickBooksIntegration: {
              ...ctx.getState().quickBooksIntegration,
              ...quickBooksAPISettings,
              Data: mapToQBOSettingsData(
                quickBooksAPISettings.Data as QuickBooksAPISettingsData
              ),
            },
          });
          ctx.dispatch(new Auth.CheckSession());
        }),
        catchError(() => {
          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.QUICKBOOKS_NOT_SAVED())
          );
          return EMPTY;
        })
      );
  }

  @Action(QuickBooksIntegrationActions.SearchQuickBooksCompanyRecords)
  private _searchQuickBooksCompanyRecords(
    ctx: LocalStateContext,
    { terms, type }: QuickBooksIntegrationActions.SearchQuickBooksCompanyRecords
  ) {
    ctx.patchState({
      quickBooksCompanyRecords: [],
    });

    return this._quickBooksApiService
      .search({
        searchCriteria: {
          from: 1,
          size: 20,
          type:
            type === QuickBooksCompaniesLinkingType.Customers
              ? 'company'
              : 'vendor',
          term: terms,
        },
      })
      .pipe(
        tap((quickBooksCompanyRecords) => {
          ctx.patchState({
            quickBooksCompanyRecords,
          });
        })
      );
  }

  private _updateDefaultSendingOptions(
    settings: QuickBooksAPISettings | null
  ): Observable<QuickBooksAPISettings | null> {
    if (settings?.Id && !this._containsAllSendOptions(settings)) {
      return this._service.updateQuickBooksIntegrationSettings({
        ...settings,
        Data: {
          ...settings.Data,
          'Option:SendBills': true,
          'Option:SendInvoices': true,
          'Option:SendPurchaseOrders': true,
        },
      });
    } else {
      return of(settings);
    }
  }

  private _containsAllSendOptions(settings: QuickBooksAPISettings): boolean {
    if (!settings.Data) return false;
    return [
      'Option:SendBills',
      'Option:SendInvoices',
      'Option:SendPurchaseOrders',
    ].every((option) =>
      Object.prototype.hasOwnProperty.call(settings.Data, option)
    );
  }
}
