export enum RoleCode {
  Administrator = 'Administrator',
  Manager = 'Manager',
  PVAdmin = 'PV Admin',
  DataExporter = 'Data Exporter',
  DefaultUser = 'DefaultUser',
  User = 'User',
  CreatePO = 'Create PO',
  CreateInvoice = 'Create Invoice',
  Orders = 'Orders',
  CreateStore = 'Create Store',
  SuperUser = 'Super User',
}

export class Role {
  Id!: number;
  Code!: RoleCode;
  Name!: RoleCode;
}

export enum RoleTranslationKey {
  Administrator = 'administrator',
  Manager = 'manager',
  PVAdmin = 'pv-admin',
  DataExporter = 'data-exporter',
  DefaultUser = 'default-user',
  CreatePO = 'create-po',
  CreateInvoice = 'create-invoice',
  User = 'user',
  Orders = 'orders',
  CreateStore = 'create-store',
  SuperUser = 'super-user',
}

export const ActiveRoleTranslationsMap: Record<RoleCode, string> = {
  'Data Exporter': RoleTranslationKey.DataExporter,
  Administrator: RoleTranslationKey.Administrator,
  'PV Admin': RoleTranslationKey.PVAdmin,
  Manager: RoleTranslationKey.Manager,
  'Create PO': RoleTranslationKey.CreatePO,
  'Create Invoice': RoleTranslationKey.CreateInvoice,
  DefaultUser: RoleTranslationKey.DefaultUser,
  'Super User': RoleTranslationKey.SuperUser,
  User: RoleTranslationKey.User,
  Orders: RoleTranslationKey.Orders,
  'Create Store': RoleTranslationKey.CreateStore,
};

export class User {
  Id!: number;
  PersonId!: number;
  Name!: string;
  IsActive!: boolean;
  Email?: string;
  Roles!: Role[];
  IconImageUrl?: string;
  CreateDate!: string;
  ActiveRoleTranslations?: string[];

  constructor(user: Partial<User>) {
    Object.assign(this, user);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  hasRole = (role: string) => {
    return this.Roles.findIndex((x) => x.Code === role) > -1;
  };
}

export interface UserDto {
  Id: number;
  Name: string;
  PrimaryEmail: string;
  Roles: RoleCode[];
}
