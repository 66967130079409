import { Injectable } from '@angular/core';
import type { Params } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import { transformSearchCriteriaIntoParams } from '@cosmos/util-common';
import { EspRestClient } from '@esp/common/data-access-rest-client';
import type { SearchCriteria, SearchResult } from '@esp/common/types';
import { searchCriteriaToHttpParams } from '@esp/common/util-http';
import type { CommonAggregation } from '@esp/orders/types';
import type {
  QuickBooksAPISettings,
  QuickBooksAuthInfo,
  QuickBooksCompanyInfo,
  QuickBooksPartySearch,
  QuickBooksTaxOption,
} from '@esp/quickbooks/types';

@Injectable({
  providedIn: 'root',
})
export class QuickBooksIntegrationService extends EspRestClient {
  override url = '';

  constructor(configService: ConfigService) {
    super(configService.get('espServiceApiUrl'));
  }

  getQuickBooksAuthInfo(): Observable<QuickBooksAuthInfo> {
    return this.http.get<QuickBooksAuthInfo>(
      `${this.uri}integrations/QBO/authinfo`
    );
  }

  getQuickBooksCompanyInfo(): Observable<QuickBooksCompanyInfo> {
    return this.http.get<QuickBooksCompanyInfo>(
      `${this.uri}integrations/QBO/command/GetCompanyInfo`
    );
  }

  getQuickBooksSettings() {
    // It will return `null` in the response when QuickBooks is disconnected
    // from the integration settings.
    return this.http.get<QuickBooksAPISettings | null>(
      `${this.uri}integrations/qbo`
    );
  }

  uninstallQuickBooks(): Observable<void> {
    return this.http.delete<void>(`${this.uri}integrations/QBO`);
  }

  getQuickBooksAccounts(
    payload: SearchCriteria
  ): Observable<QuickBooksPartySearch[]> {
    const params = searchCriteriaToHttpParams(payload);
    return this.http.get<QuickBooksPartySearch[]>(
      `${this.uri}integrations/QBO/records/account/search`,
      {
        params,
      }
    );
  }

  getQuickBooksTaxOptions(
    payload: SearchCriteria
  ): Observable<QuickBooksTaxOption[]> {
    const params = searchCriteriaToHttpParams(payload);
    return this.http.get<QuickBooksTaxOption[]>(
      `${this.uri}integrations/QBO/records/tax/search`,
      {
        params,
      }
    );
  }

  updateQuickBooksIntegrationSettings(
    payload: QuickBooksAPISettings
  ): Observable<QuickBooksAPISettings> {
    return this.http.put<QuickBooksAPISettings>(
      `${this.uri}integrations/QBO`,
      payload
    );
  }

  authorizeQuickBooksIntegration(): Observable<string> {
    return this.http.get<string>(`${this.uri}integrations/qbo/authorize`);
  }

  installQuickBooksIntegration(queryParams: Params): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.uri}integrations/qbo/oauth2callback`,
      { params: queryParams }
    );
  }

  getNotLinkedCompanies(
    criteria: SearchCriteria
  ): Observable<SearchResult<CommonAggregation>> {
    return this.http.get<SearchResult<CommonAggregation>>(
      `${this.uri}companies/notlinkedto/QBO`,
      {
        params: transformSearchCriteriaIntoParams(criteria),
      }
    );
  }

  linkMultipleCompanies(
    links: Record<string, string | number>
  ): Observable<void> {
    return this.http.put<void>(
      `${this.uri}integrations/QBO/records/company/link`,
      links
    );
  }
}
