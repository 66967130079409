import { createPropertySelectors, createSelector } from '@ngxs/store';

import {
  createLoadingSelectorsFor,
  createOperationSelectorsFor,
} from '@cosmos/state';
import { User, UserProfileQueries } from '@esp/auth/data-access-auth';
import { QuickBooksAccountDescription } from '@esp/quickbooks/types';

import {
  QuickBooksIntegrationState,
  type QuickBooksIntegrationStateModel,
} from '../states/quickbooks-integration.state';

export namespace QuickBooksIntegrationQueries {
  export const { isLoading, hasLoaded, getLoadError } =
    createLoadingSelectorsFor<QuickBooksIntegrationStateModel>(
      QuickBooksIntegrationState
    );

  export const {
    quickBooksIntegration,
    quickBooksAccounts,
    quickBooksAccountsLoading,
    quickBooksTaxOptions,
    quickBooksCompanyRecords,
  } = createPropertySelectors<QuickBooksIntegrationStateModel>(
    QuickBooksIntegrationState
  );

  export const { isInProgress: areAccountsLoading } =
    createOperationSelectorsFor(quickBooksAccountsLoading);

  export const quickBooksAccountsMap = createSelector(
    [quickBooksAccounts],
    (quickBooksAccounts) => {
      return quickBooksAccounts.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.ExternalId]: curr,
        };
      }, {});
    }
  );

  export const payableAccounts = createSelector(
    [quickBooksAccounts],
    (quickBooksAccounts) => {
      return quickBooksAccounts.filter(
        (account) =>
          account.Description === QuickBooksAccountDescription.AccountsPayable
      );
    }
  );

  export const payableAccountsMap = createSelector(
    [payableAccounts],
    (payableAccounts) => {
      return payableAccounts.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.ExternalId]: curr,
        };
      }, {});
    }
  );

  export const incomeAccounts = createSelector(
    [quickBooksAccounts],
    (quickBooksAccounts) => {
      return quickBooksAccounts.filter((account) =>
        [
          QuickBooksAccountDescription.Income,
          QuickBooksAccountDescription.OtherIncome,
        ].includes(account.Description as QuickBooksAccountDescription)
      );
    }
  );

  export const incomeAccountsMap = createSelector(
    [incomeAccounts],
    (incomeAccounts) => {
      return incomeAccounts.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.ExternalId]: curr,
        };
      }, {});
    }
  );

  export const recivableAccounts = createSelector(
    [quickBooksAccounts],
    (quickBooksAccounts) => {
      return quickBooksAccounts.filter(
        (account) =>
          account.Description ===
          QuickBooksAccountDescription.AccountsReceivable
      );
    }
  );

  export const recivableAccountsMap = createSelector(
    [recivableAccounts],
    (recivableAccounts) => {
      return recivableAccounts.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.ExternalId]: curr,
        };
      }, {});
    }
  );

  export const costOfGoodsSoldAccounts = createSelector(
    [quickBooksAccounts],
    (quickBooksAccounts) => {
      return quickBooksAccounts.filter(
        (account) =>
          account.Description === QuickBooksAccountDescription.CostofGoodsSold
      );
    }
  );

  export const costOfGoodsSoldAccountsMap = createSelector(
    [costOfGoodsSoldAccounts],
    (costOfGoodsSoldAccounts) => {
      return costOfGoodsSoldAccounts.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.ExternalId]: curr,
        };
      }, {});
    }
  );

  export const quickBooksIntegrationData = createSelector(
    [quickBooksIntegration],
    (quickBooksIntegration) => {
      return quickBooksIntegration.Data;
    }
  );

  export const quickBooksBaseUrl = createSelector(
    [quickBooksIntegration],
    (integration) => integration?.Settings?.UIBaseUrl ?? ''
  );

  export const updateQuickBooksOperation = createSelector(
    [QuickBooksIntegrationState],
    (state: QuickBooksIntegrationStateModel) => state.updateQuickBooksOperation
  );

  export const isAnySendAllowed = createSelector(
    [quickBooksIntegration],
    (integration) =>
      !!(
        integration.Data?.SendInvoices ||
        integration.Data?.SendBills ||
        integration.Data?.SendPurchaseOrders
      )
  );

  const isAdministrator = createSelector(
    [UserProfileQueries.getUser],
    (user) => (user ? new User(user).isAdministrator : false)
  );

  const userId = createSelector(
    [UserProfileQueries.getUser],
    (user) => user?.Id ?? 0
  );

  export const isQuickBooksInstalled = createSelector(
    [quickBooksIntegration],
    (integration) => {
      return !!integration.Id;
    }
  );

  export const isQuickBooksActive = createSelector(
    [isQuickBooksInstalled, quickBooksIntegration],
    (isInstalled, integration) => !!(isInstalled && integration.IsActive)
  );

  export const hasQuickBooksWriteAccess = createSelector(
    [isQuickBooksActive, isAdministrator, userId, quickBooksIntegration],
    (isActive, isAdministrator, userId, quickBooksIntegration) =>
      isActive &&
      !!(
        isAdministrator ||
        quickBooksIntegration.AccessLevel === 'Everyone' ||
        quickBooksIntegration.Access?.map((access) => access.EntityId).includes(
          userId
        )
      )
  );

  export const hasQuickBooksCreateCompanyAccess = createSelector(
    [isQuickBooksInstalled, isAdministrator, userId, quickBooksIntegration],
    (isQuickBooksInstalled, isAdministrator, userId, quickBooksIntegration) =>
      isQuickBooksInstalled &&
      !!(
        isAdministrator ||
        quickBooksIntegration.AccessLevel === 'Everyone' ||
        quickBooksIntegration.Access?.map((access) => access.EntityId).includes(
          userId
        )
      )
  );

  export const canSendBills = createSelector(
    [isQuickBooksActive, quickBooksIntegration],
    (isActive, quickBooksIntegration) =>
      isActive && !!quickBooksIntegration.Data?.SendBills
  );

  export const canSendPurchaseOrders = createSelector(
    [isQuickBooksActive, quickBooksIntegration],
    (isActive, quickBooksIntegration) =>
      isActive && !!quickBooksIntegration.Data?.SendPurchaseOrders
  );

  export const canSendInvoices = createSelector(
    [isQuickBooksActive, quickBooksIntegration],
    (isActive, quickBooksIntegration) =>
      isActive && !!quickBooksIntegration.Data?.SendInvoices
  );

  export const isQuickBooksCompanyCanadian = createSelector(
    [quickBooksIntegration],
    (integration) =>
      Boolean(integration.Country && integration.Country === 'CA')
  );
}
