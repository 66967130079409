import { DocumentType } from '@esp/settings/types';

export const getDocumentTypeName = (documentType: DocumentType): string => {
  switch (documentType) {
    case DocumentType.Quote:
      return 'Quote';
    case DocumentType.QuoteRequest:
      return 'Quote Request';
    case DocumentType.Acknowledgement:
      return 'Acknowledgement';
    case DocumentType.PO:
      return 'Purchase Order';
    case DocumentType.PackingList:
      return 'Packing List';
    case DocumentType.SalesOrder:
      return 'Sales Order';
    case DocumentType.Invoice:
      return 'Invoice';
    default:
      return documentType;
  }
};
