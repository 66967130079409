import type { Setting } from '@esp/settings/types';

const ACTION_SCOPE = '[PaymentProvidersSettings]' as const;

export namespace PaymentProvidersSettingsActions {
  export class LoadSettings {
    static type = `${ACTION_SCOPE} Load Settings`;
  }

  export class ConnectProvider {
    static type = `${ACTION_SCOPE} Connect Provider`;
    constructor(
      public payload: Partial<Setting>[],
      public providerName: string
    ) {}
  }

  export class DisconnectProvider {
    static type = `${ACTION_SCOPE} Disconnect Provider`;
    constructor(
      public payload: Partial<Setting>[],
      public providerName: string
    ) {}
  }

  export class RetrieveOAuthLinkForStripe {
    static type = `${ACTION_SCOPE} Retrieve OAuth link for Stripe`;
    constructor() {}
  }

  export class ConnectStripeAccount {
    static type = `${ACTION_SCOPE} Connect Stripe Account`;
    constructor(public code: string) {}
  }
}
