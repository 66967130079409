export interface SimpleParty {
  Id: number;
  Name: string;
  Address: any;
  Email: string;
  EmailType: string;
  Phone: string;
  IsCompany: boolean;
  IsPerson: boolean;
  IsUser: boolean;
  ExternalRecordId: string;
  Links: SimpleParty[];
}

export interface OrderEmailTemplate {
  ReplyTo?: string;
  CC?: SimpleParty[];
  AllowCustomSignature: boolean;
  Type: OrderEmailTemplateType;
  Subject: string;
  Header?: string;
  Body: string;
  Signature?: string;
  Footer: string;
}

// Given this one of the fields that's related to `acknowledgement` email type.
export interface OrderEmailMergedField {
  // The `Code` might be `Order.Totals.TotalAmount`.
  Code: string;
  // The `Name` might be `Order Total`.
  Name: string;
  // The `Preview` might be `$1,500`.
  Preview: string;
}

export enum OrderEmailTemplateType {
  Acknowledgement = 'acknowledgement',
  Invoice = 'invoice',
  Quote = 'quote',
  PackingList = 'packinglist',
  SalesOrder = 'salesorder',
  PurchaseOrder = 'po',
  QuoteRequest = 'quoterequest',
  SampleRequest = 'samplerequest',
  ProFormaInvoice = 'ackp',
  ProFormaInvoiceWithSuffix = 'ackps',
  // Note: it should be titlecased.
  Presentation = 'Presentation',
  Proof = 'Proof',
  // This is a virtual order template type, it doesn't exist on email templates page,
  // it's only used for mapping merged fields from `order` to `salesorder`.
  Order = 'order',
  PaymentRequest = 'paymentrequest',
  CancelStoreOrder = 'cancelweborder',
}
