import type { CompanyProfileSetting } from '@esp/settings/types';

const ACTION_SCOPE = '[CompanyProfile]' as const;

export namespace CompanyProfileActions {
  export class Load {
    static readonly type = `${ACTION_SCOPE} Load`;
  }

  export class Update {
    static readonly type = `${ACTION_SCOPE} Update`;

    constructor(public payload: Partial<CompanyProfileSetting>) {}
  }
}
