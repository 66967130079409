export enum DocumentType {
  Acknowledgement = 'acknowledgement',
  CancelStoreOrder = 'cancelweborder',
  Invoice = 'invoice',
  PackingList = 'packinglist',
  Quote = 'quote',
  QuoteRequest = 'quoterequest',
  SampleRequest = 'samplerequest',
  PaymentRequest = 'paymentrequest',
  SalesOrder = 'salesorder',
  PO = 'po',
  ACKP = 'ackp',
  ACKPS = 'ackps',
}

export interface DocumentSetting {
  TenantId: number;
  DocumentTypeSettings: DocumentTypeSetting[];
  ImageUrl: string;
  OriginalImageName: string;
  Title: string;
  UseNewFormat: boolean;
  CreateDate: string;
  CreateOffset: string;
  CreatedBy: string;
  UpdateDate: string;
  UpdateOffset: string;
  UpdatedBy: string;
  AuditId: string;
  IpAddress: string;
  Signon_Id: number;
  Id: number;
  ShowImageUrl: boolean;
}

export interface DocumentTypeSetting {
  DocumentSetting_Id: number;
  DocumentSetting: DocumentSetting;
  DocumentType: DocumentType;
  Header: string;
  Footer: string;
  ShowDocumentImages: boolean;
  ShowCPN: boolean;
  ShowShippingContactPhone: boolean;
  ShowShippingContactEmail: boolean;
  ShowPOReference: boolean;
  ShowThirdPartyId: boolean;
  CreateDate: string;
  CreateOffset: string;
  CreatedBy: string;
  UpdateDate: string;
  UpdateOFfset: string;
  UpdatedBy: string;
  AuditId: string;
  IpAddress: string;
  Signon_Id: number;
  Id: number;
}

export type DesignSettingCode = keyof DocumentSetting;

export const documentTypeKeys: Record<DocumentType, string> = {
  acknowledgement: 'espOrders.document-types.acknowledgement',
  invoice: 'espOrders.document-types.invoice',
  packinglist: 'espOrders.document-types.packinglist',
  quote: 'espOrders.document-types.quote',
  quoterequest: 'espOrders.document-types.quoterequest',
  samplerequest: 'espOrders.document-types.samplerequest',
  paymentrequest: 'espOrders.document-types.paymentrequest',
  salesorder: 'espOrders.document-types.salesorder',
  po: 'espOrders.document-types.po',
  ackp: 'espOrders.document-types.ackp',
  ackps: 'espOrders.document-types.ackps',
  cancelweborder: 'espOrders.document-types.cancelweborder',
};
