import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import { EspRestClient } from '@esp/common/data-access-rest-client';
import type { ProjectResolution } from '@esp/orders/types';

@Injectable({
  providedIn: 'root',
})
export class ProjectResolutionsApiService extends EspRestClient {
  constructor(configService: ConfigService) {
    super(configService.get('vulcanApiUrl'));
  }

  getResolutions(): Observable<ProjectResolution[]> {
    return this.http.get<ProjectResolution[]>(`${this.uri}lookups/resolutions`);
  }

  postResolution(resolution: ProjectResolution): Observable<ProjectResolution> {
    return this.http.post<ProjectResolution>(
      `${this.uri}settings/resolutions`,
      resolution
    );
  }

  putResolution(resolution: ProjectResolution): Observable<ProjectResolution> {
    return this.http.put<ProjectResolution>(
      `${this.uri}settings/resolutions/${resolution.Id}`,
      resolution
    );
  }

  deleteResolution(resolutionId: number): Observable<ProjectResolution> {
    return this.http.delete<ProjectResolution>(
      `${this.uri}settings/resolutions/${resolutionId}`
    );
  }
}
